import React, { useState, useLayoutEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import logo from "../logo.svg";
import "../App.css";
import axios from "axios";
import { imgobj } from "./Images";
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2';

export default function UserForm() {
  const [Mainset, setMainset] = useState(false);
  const [width, setWidth] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [campusPlacement, setCampusPlacement] = useState("");
  const [doj, setDoj] = useState("");
  const [offerLetter, setOfferLetter] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [ctc, setCtc] = useState("");
  const [id, setId] = useState("");
  const [mobile, setMobile] = useState("");
  const [job, setJob] = useState("");
  const [currentOrganisation, setCurrentOrganisation] = useState("");
  const [highestQualification, setHighestQualification] = useState("");
  const handleSubmit = async () => {
    try {
      if (name == "") {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: `Name Cannot be empty`,
        })
        return;
      }
      else if (email == "") {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: `Email Cannot be empty`,
        })
        return;
      }
      else if (campusPlacement == "") {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: `Campus Placement Cannot be empty`,
        })
        return;
      }
      else if (linkedIn == "") {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: `LinkedIn account link Cannot be empty`,
        })
        return;
      }
      else if (facebook == "") {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: `Facebook account link Cannot be empty`,
        })
        return;
      }
      else if (instagram == "") {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: `Instagram account link Cannot be empty`,
        })
        return;
      }
      else if (highestQualification == "") {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: `Highest Qualification Cannot be empty`,
        })
        return;
      }
      else if (currentOrganisation == "") {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: `Current Organisation Cannot be empty`,
        })
        return;
      }
      else if (ctc == "") {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: `CTC Cannot be empty`,
        })
        return;
      }
      else if (job == "") {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: `Department/Profile Cannot be empty`,
        })
        return;
      }
      else if (mobile == "") {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: `Mobile Number Cannot be empty`,
        })
        return;
      }
      else if (mobile.length != 10) {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: `Mobile Number Cannot be more than or less than 10`,
        })
        return;
      }
      else if (!id) {
        Swal.fire({
          icon: "error",
          title: 'Error',
          text: `Please Upload Employee ID card/Passport photo to proceed`,
        })
        return;
      }

      let formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("campusPlacement", campusPlacement);
      formData.append("doj", doj);
      formData.append("linkedIn", linkedIn);
      formData.append("highestQualification", highestQualification);
      formData.append("currentOrganisation", currentOrganisation);
      formData.append("facebook", facebook);
      formData.append("instagram", instagram);
      formData.append("ctc", ctc);
      formData.append("job", job);
      formData.append("mobile", mobile);
      formData.append("file2", id);
      console.log({
        name,
        email,
        campusPlacement,
        doj,
        offerLetter,
        linkedIn,
        facebook,
        instagram,
        ctc,
        job,
        mobile,
        id,
      });


      // let { data: res } = await axios.post(`http://localhost:4051/api/enquiry/`, formData);
      let { data: res } = await axios.post(`/api/api/enquiry/`, formData);
      if (res.success) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: `${res.message}`,
        })
        setName("");
        setEmail("");
        setCampusPlacement("");
        setDoj("");
        setOfferLetter("");
        setLinkedIn("");
        setFacebook("");
        setInstagram("");
        setCtc("");
        setId("");
        setMobile("");
        setJob("");
        setCurrentOrganisation("");
        setHighestQualification("");
      }
      console.log(res, "response from backend");
    } catch (err) {
      if (err.response.data.message) {
        console.log(err.response.data.message);
        alert(err.response.data.message);
      } else {
        console.log(err.message);
        alert(err.message);
      }
    }
  };

  return (
    <>
      <section className="header position-absolute top-0 w-100 start-0">
        <div className="container">
          <div className="logo">
            <img
              src={imgobj.logo}
              //   width="100%"
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="faculty-banner">
        <div className="container text-center">
          <div className="col-12 col-md-6 mx-auto">
            <div className="title-section mb-4">
              <h2 className="heading text-white">
                CEC (Career Enhancment Cell)
              </h2>
            </div>
            <p className="text-white">
              {" "}
              It is our constant endeavour to support and be THE catalyst in the professional growth and career stability of our students. Our alumni PSIBians can opt for fresh interview and recruitment opportunities @zero cost, after completing 14 months of service through their respective campus placement.
            </p>
          </div>
        </div>
      </section>

      <section className="main_form_sec ptb-80">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="title-section">
                <h2
                  className="heading text-dark bottom-border"
                  style={{ width: "max-content" }}
                >
                  Fill Your details
                </h2>
              </div>
              <div className="contact-form-left">
                <div className="water_mark"></div>
                <form className="form row">
                  <div className="col-12 col-md-6">
                    <label htmlFor="">Name</label>
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      type="text"
                      placeholder="Enter your Name"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="">Email</label>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      type="text"
                      placeholder="Enter your Email"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="">Campus Placement Bank/Company</label>
                    <input
                      value={campusPlacement}
                      onChange={(e) => setCampusPlacement(e.target.value)}
                      className="form-control"
                      type="text"
                      placeholder="Enter your Campus Placement Bank/Company"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="">Date of Joining</label>
                    <input
                      onChange={(e) => setDoj(e.target.value)}
                      className="form-control"
                      type="date"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="">LinkedIn Account Link</label>
                    <input
                      value={linkedIn}
                      onChange={(e) => setLinkedIn(e.target.value)}
                      className="form-control"
                      type="text"
                      placeholder="Enter your LinkedIn Account Link "
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="">Facebook Account Link</label>
                    <input
                      value={facebook}
                      onChange={(e) => setFacebook(e.target.value)}
                      className="form-control"
                      type="text"
                      placeholder="Enter your Facebook Account Link "
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="">Instagram Account Link</label>
                    <input
                      value={instagram}
                      onChange={(e) => setInstagram(e.target.value)}
                      className="form-control"
                      type="text"
                      placeholder="Enter your Instagram Account Link "
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="">Highest Qualification</label>
                    <input
                      onChange={(e) => setHighestQualification(e.target.value)}
                      value={highestQualification}
                      className="form-control"
                      type="text"
                      placeholder="Your Highest Qualification"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="">Current Organisation</label>
                    <input
                      onChange={(e) => setCurrentOrganisation(e.target.value)}
                      className="form-control"
                      value={currentOrganisation}
                      type="text"
                      placeholder="Your Current Organisation"
                    />
                  </div>

                  <div className="col-12 col-md-6">
                    <label htmlFor="">Current CTC</label>
                    <input
                      value={ctc}
                      onChange={(e) => setCtc(e.target.value)}
                      className="form-control"
                      type="text"
                      placeholder="Enter your Current CTC "
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="">Department /Profile</label>
                    <input
                      value={job}
                      onChange={(e) => setJob(e.target.value)}
                      className="form-control"
                      type="text"
                      placeholder="Enter your Department /Profile "
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="">Mobile Number</label>
                    <input
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      className="form-control"
                      type="tel"
                      placeholder="Enter your Mobile Number "
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="">Employee ID card/Passport photo</label>
                    <div className="id_div position-relative">
                      <input
                        onChange={(e) => setId(e.target.files[0])}
                        className="form-control w-100 mb-0"
                        type="file"
                        placeholder="Your Id"
                      />
                      <p
                        className="sample_btn position-absolute"
                        onMouseOver={() => setMainset(true)}
                        onMouseOut={() => setMainset(false)}
                      >
                        {" "}
                        Sample
                      </p>
                      {Mainset && (
                        <div className="hov_div position-absolute">
                          <img
                            src={imgobj.input}
                            width="100%"
                            height={"100%"}
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 mb-0">
                    <button
                      className="buttons"
                      type="button"
                      onClick={() => handleSubmit()}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-80">
        <div className="container">
          <div className="row">
            <div className="title-section">
              <h2
                className="heading text-dark bottom-border"
                style={{ width: "max-content" }}
              >
                Our Recruiting Partners
              </h2>
            </div>
          </div>
          <div className="our_partners">
            <div className="row ">
              <div className="col">
                <div className="partners_img">
                  <img src={imgobj.partener_1} width="100%" alt="" />
                </div>
              </div>
              <div className="col">
                <div className="partners_img">
                  <img src={imgobj.partener_2} width="100%" alt="" />
                </div>
              </div>
              <div className="col">
                <div className="partners_img">
                  <img src={imgobj.partener_3} width="100%" alt="" />
                </div>
              </div>
              <div className="col">
                <div className="partners_img ">
                  <img src={imgobj.partener_4} width="100%" alt="" />
                </div>
              </div>
              <div className="col">
                <div className="partners_img">
                  <img src={imgobj.partener_5} width="100%" alt="" />
                </div>
              </div>
              <div className="col">
                <div className="partners_img">
                  <img src={imgobj.partener_6} width="100%" alt="" />
                </div>
              </div>
              <div className="col">
                <div className="partners_img">
                  <img src={imgobj.partener_7} width="100%" alt="" />
                </div>
              </div>
              <div className="col">
                <div className="partners_img">
                  <img src={imgobj.partener_8} width="100%" alt="" />
                </div>
              </div>
              <div className="col">
                <div className="partners_img ">
                  <img src={imgobj.partener_9} width="100%" alt="" />
                </div>
              </div>
              <div className="col">
                <div className="partners_img">
                  <img src={imgobj.partener_10} width="100%" alt="" />
                </div>
              </div>
              <div className="col">
                <div className="partners_img">
                  <img src={imgobj.kotak} width="100%" alt="" />
                </div>
              </div>
              <div className="col">
                <div className="partners_img">
                  <img src={imgobj.partener_11} width="100%" alt="" />
                </div>
              </div>
              <div className="col">
                <div className="partners_img">
                  <img src={imgobj.partener_12} width="100%" alt="" />
                </div>
              </div>
              <div className="col">
                <div className="partners_img">
                  <img src={imgobj.partener_13} width="100%" alt="" />
                </div>
              </div>
              <div className="col">
                <div className="partners_img">
                  <img src={imgobj.partener_14} width="100%" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="copyright">
        <div className="container">
          <p>Copyright © 2022 By Ebslon Infotech</p>
        </div>
      </div>
    </>
  );
}
