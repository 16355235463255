import './App.css';
import { Routes, Route, Link, BrowserRouter } from "react-router-dom";
import UserForm from './Components/UserForm';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<UserForm />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
