import partener_1 from "../Asset/images/partener_1.png";
import partener_2 from "../Asset/images/partener_2.png";
import partener_3 from "../Asset/images/partener_3.png";
import partener_4 from "../Asset/images/partener_4.png";
import partener_5 from "../Asset/images/partener_5.png";
import partener_6 from "../Asset/images/partener_6.png";
import partener_7 from "../Asset/images/partener_7.png";
import partener_8 from "../Asset/images/partener_8.png";
import partener_9 from "../Asset/images/partener_9.png";
import partener_10 from "../Asset/images/partener_10.png";
import partener_11 from "../Asset/images/partner_11.png";
import partener_12 from "../Asset/images/partner_12.png";
import partener_13 from "../Asset/images/partner_13.png";
import partener_14 from "../Asset/images/partner_14.png";
import logo from "../Asset/images/logo.png";
import about_bg from "../Asset/images/about_bg.png";
import input from "../Asset/images/input.png";
import kotak from "../Asset/images/kotak.png";

export const imgobj = {
  partener_1,
  partener_2,
  partener_3,
  partener_4,
  partener_5,
  partener_6,
  partener_7,
  partener_8,
  partener_9,
  partener_10,
  partener_11,
  partener_12,
  partener_13,
  partener_14,
  logo,
  about_bg,
  input,
  kotak,
};
